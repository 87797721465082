import React from "react"
import PageLayout from "../components/new-pagelayout"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const NotFound = () => {
  return (
    <PageLayout pageTitle={"404"}>
      <section class="relative lg:pt-20 overflow-hidden">
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap items-center">
            <div class="w-full lg:w-7/12 mt-12 mb-20 text-center">
              <span class="text-xs font-semibold text-gray-500 uppercase">
                Status 404
              </span>
              <h2 class="mt-8 mb-10 text-4xl font-semibold font-heading">
                404 – Här var det tomt!
              </h2>
              <p class="mb-12 text-xl text-gray-500">
                Du har lyckats navigera till en sida som inte finns! Kanske du
                kan hitta vad du söker om du försöker igen?
              </p>
              <div class="flex flex-wrap justify-center">
                <Link
                  class="w-full md:w-auto px-8 py-4 mb-4 md:mb-0 md:mr-4 bg-red-400 hover:bg-red-300 text-sm text-white font-medium leading-normal rounded"
                  to="/"
                >
                  Börja från början
                </Link>
                <Link
                  class="w-full md:w-auto px-12 py-4 border hover:border-gray-500 text-sm font-medium leading-normal rounded"
                  to="#"
                >
                  En gång till!
                </Link>
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          //class="lg:absolute lg:top-0 lg:right-0 h-128 lg:h-auto max-h-screen w-full lg:w-5/12 object-cover"
          class="object-cover w-full lg:absolute lg:top-20 lg:right-0 lg:h-auto lg:w-5/12"
          src="../images/drone-search.jpg"
          alt=""
        />
      </section>
    </PageLayout>
  )
}

export default NotFound
